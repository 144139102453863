import { render, staticRenderFns } from "./HistoryHero.vue?vue&type=template&id=82411ec6&scoped=true&"
import script from "./HistoryHero.vue?vue&type=script&lang=ts&"
export * from "./HistoryHero.vue?vue&type=script&lang=ts&"
import style0 from "./HistoryHero.vue?vue&type=style&index=0&id=82411ec6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82411ec6",
  null
  
)

export default component.exports