
import { Component, Prop, Vue } from "vue-property-decorator"
import { mapGetters, mapState } from "vuex"
import error from "@/mixins/error"
import { CREATE_NEW_PLAN_CLICK, LEARN_MORE_FROM_HISTORY_PAGE } from "@/services/eventNames"
import { PLAN_CREATOR_MODE, ROUTE_NAMES } from "@/defaults"

@Component({
  name: "HistoryHero",
  mixins: [error],
  computed: {
    ...mapGetters(["isPremiumUser", "isBetaUser", "isAdmin"]),
    ...mapState(["userId", "isEmbedded"])
  }
})
export default class HistoryHero extends Vue {
  @Prop() private noPlans!: boolean
  private isPremiumUser!: boolean
  private userId!: string
  private error!: Function
  private $clicky!: Function
  private isAdmin!: Function

  learnMore() {
    this.$trackEvent({ name: LEARN_MORE_FROM_HISTORY_PAGE })
  }
  createNewPlan() {
    if (this.isPremiumUser) {
      this.$router.push({ name: ROUTE_NAMES.PLAN, params: { mode: PLAN_CREATOR_MODE.CREATE } })
    } else {
      this.error({ type: "go-premium", message: "You don't have premium" })
    }
    this.$trackEvent({ name: CREATE_NEW_PLAN_CLICK })
    this.$clicky(`Create plan click on history page`, { isPaid: this.isPremiumUser, userId: this.userId })
  }
  data() {
    return {
      userIdFromQuery: this.$route.query.userId
    }
  }
}
